import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout.js";
import Signup from "../components/Signup.js";
import Section from "../components/Section";
import BenefitsList from "../components/BenefitsList";
import Famous from "../components/Famous.js";

const TimLayoutOptInPageTemplate = ({ data }) => {
  return (
    <>
      <Layout
        seoObject={data.seoObject}
        imageObject={data.headerObject.imageObject}
      >
        <Section>
          <Signup signupObject={data.signupObject} />
        </Section>
        <Section className="bg-brand-beige-40">
          <Famous />
        </Section>
        <Section className="bg-brand-mint">
          <BenefitsList benefitsObject={data.benefitsObject} />
        </Section>
        <Section>
          <Signup signupObject={data.signupObject2} />
        </Section>
      </Layout>
    </>
  );
};

const TimLayoutOptInPage = ({ data }) => {
  return <TimLayoutOptInPageTemplate data={data.markdownRemark.frontmatter} />;
};

export default TimLayoutOptInPage;

export const pageQuery = graphql`
  query TimLayoutOptInPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seoObject {
          slug
          domain
          description
          index
          title
          imageObject {
            alt
            image {
              childImageSharp {
                resize(width: 1200) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
        headerObject {
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
        }
        signupObject {
          cta
          ctaSubtitle
          subtitle
          title
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          funnelId
          href
        }
        benefitsObject {
          title
          list {
            description
            iconName
          }
        }
        signupObject2 {
          cta
          ctaSubtitle
          subtitle
          title
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          funnelId
          href
        }
      }
    }
  }
`;
